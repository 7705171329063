<template>
  <ys-detail :icon="detailIcon" :loading="loading" :name="detail.type|getBaseDataName('设备类型')">
    <Tag size="medium" color="blue" slot="status">{{ detail.enabledState|getBaseDataName('设备其他状态') }}</Tag>
    <template slot="brief">
      <ys-cell title="名称" :label="detail.name"/>
      <ys-cell title="设备序列号" :label="detail.seqNo"/>
      <ys-cell title="设备状态" :label="detail.state|getBaseDataName('设备状态')"/>
      <ys-cell title="设备使用状态" :label="detail.useState|getBaseDataName('设备使用状态')"/>
      <ys-cell title="供应商" :label="detail.operator"/>
    </template>
    <template slot="main-left">
      <Tabs v-model="tab" name="detail" type="card" :key="detail.id">
        <Tab-pane label="可用机构" name="1" icon="ios-home">
          <availableOrg :midData="[detail]" :tableHeight="pageListHeight" v-if="tab==='1'"/>
        </Tab-pane>
        <Tab-pane label="使用记录" name="2" icon="ios-laptop">
          <usageRecord :midData="[detail]" :tableHeight="pageListHeight" v-if="tab==='2'"/>
        </Tab-pane>
        <Tab-pane label="定位轨迹" name="3" icon="ios-pin">
          <positioningTrack :midData="[detail]" :detail="detail" :tableHeight="pageListHeight" v-if="tab==='3'"/>
        </Tab-pane>
        <Tab-pane label="设备文件" name="4" icon="ios-folder">
          <deviceFile :midData="[detail]" :detail="detail" :tableHeight="pageListHeight" v-if="tab==='4'"/>
        </Tab-pane>
      </Tabs>
    </template>
    <template slot="main-right">
      <h1 class="h1Title">其他信息</h1>
      <ys-empty title="暂未开放！"></ys-empty>
    </template>
  </ys-detail>
</template>

<script>
import detailMixins from '@/mixins/detail'
import availableOrg from '@/views/base/smartDevice/detail/availableOrg'
import usageRecord from '@/views/base/smartDevice/detail/usageRecord/index'
import positioningTrack from '@/views/base/smartDevice/detail/usageRecord/detail/positioningTrack'
import deviceFile from '@/views/base/smartDevice/detail/usageRecord/detail/deviceFile'
import {hardHatGetBySeqNo} from "@/api/base";

export default {
  name: "detail",
  mixins: [detailMixins],
  components: {
    availableOrg,
    usageRecord,
    positioningTrack,
    deviceFile,
  },
  props: {
    seqNo: {
      type: [String, Number],
      default: ''
    }
  },
  data() {
    return {}
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    getDetail() {
      if(!this.seqNo) return
      this.loading = true;
      this.detail={};
      // 查询安全帽模块信息
      hardHatGetBySeqNo({seqNo: this.seqNo}).then(res => {
        this.loading = false;
        this.detail = res.data;
      })
    }
  },
  watch: {
    "seqNo"() {
      this.getDetail();
    }
  }
}
</script>

<style scoped>
#container {
  width: 100%;
  height: 450px;
}
</style>