<template>
  <div>
    <ys-page-list
        ref="table"
        :multiBtn="multiBtn"
        showContextMenu
        :params="params"
        action="/hardHat/GetBindOrgsBySeqNo"
        :tableHeight="tableHeight - 50"
        :isPage="false"
        @on-selection-change="checkItem"
        :headerColumns="headerColumns">
      <template slot="search">
        <!-- <FormItem label="关键词" prop="keyword">
          <Input placeholder="关键词" clearable style="width: 150px;" v-model="params.keyword"></Input>
        </FormItem> -->
      </template>
      <template slot="btn">
        <ButtonGroup>
          <Button type="primary" @click="orgFlag=true">绑定机构</Button>
        </ButtonGroup>
      </template>
    </ys-page-list>
    <ys-modal v-model="orgFlag" :width="1200" title="选择机构">
      <org-component :limit="1" :oType="3" :oTypeListValue="[3]" @on-ok="getData"></org-component>
    </ys-modal>
  </div>
</template>

<script>
import pageList from '@/mixins/pageList.js'
import OrgComponent from "_c/org";
import {hardHatBindOrgBySeqNo, hardHatUnbindOrgBySeqNo} from "@/api/base";

export default {
  name: '',
  mixins: [pageList],
  components: {
    OrgComponent
  },
  props: {},
  data() {
    return {
      params: {
        seqNo: '',
        keyword: '',
      },
      headerColumns: [
        {type: 'selection', title: '选择框', width: 60, align: 'center'},
        {type: 'index', title: '序号', width: 50, align: 'center'},
        {minWidth: 150, title: '机构名称', key: 'orgName', align: 'center'},
        {width: 100, title: '联系人', key: 'contacts', align: 'center'},
        {width: 120, title: '联系电话', key: 'phone', align: 'center'},
        {width: 200, title: '录入时间', key: 'createdAt', align: 'center'},
        // {width: 60, title: '状态', key: '', align: 'center', renderConfig: {type: 'baseData', parentName: ''}},
      ],
      orgFlag: false,
    }
  },
  computed: {
    multiBtn() {
      return [
        {
          children: [
            {
              click: () => this.Unbind(),
              title: '解绑',
              icon: 'md-key',
              disabled: this.tableSelectItem.length !== 1
            },
          ]
        }
      ]
    },
  },
  mounted() {
    this.params.seqNo = this.midData[0].seqNo || ''
    if(!this.params.seqNo) return
    this.getList();
  },
  methods: {
    getData(data) {
      // 绑定服务机构
      hardHatBindOrgBySeqNo({seqNo: this.params.seqNo, orgId: data[0].id}).then(res => {
        if (res.code === 200) {
          this.$Notice.success({
            title: '提示',
            desc: '操作成功！'
          });
          this.getList();
        }
      })
    },
    // 解绑服务机构
    Unbind() {
      this.$Modal.confirm({
        title: '注意',
        content: '是否要解绑当前服务机构?',
        okText: '确定',
        cancelText: '取消',
        onOk: () => {
          const {orgId, seqNo} = this.tableSelectItem[0]
          hardHatUnbindOrgBySeqNo({seqNo, orgId}).then(res => {
            if (res.code === 200) {
              this.$Notice.success({
                title: '提示',
                desc: '操作成功！'
              });
              this.getList();
            }
          })
        }
      })
    }
  }
}
</script>

<style>

</style>