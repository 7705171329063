<template>
  <div>
    <ys-page-list
        ref="table"
        :multiBtn="multiBtn"
        showContextMenu
        keyID="7FD459665D8E823D068043CC7101F51B"
        :params="params"
        action="/hardHat/List"
        :tableHeight="tableHeight"
        @on-selection-change="checkItem"
        :headerColumns="headerColumns">
      <template slot="search">
        <FormItem label="设备类型" prop="type">
          <Select v-model="params.type" clearable placeholder="设备类型" style="width: 120px">
            <Option v-for="(item,index) in baseData['设备类型']" :value="item.value" :key="index">{{ item.name }}</Option>
          </Select>
        </FormItem>
        <FormItem label="设备状态" prop="state">
          <Select v-model="params.state" clearable placeholder="设备状态" style="width: 120px">
            <Option v-for="(item,index) in baseData['设备状态']" :value="item.value" :key="index">{{ item.name }}</Option>
          </Select>
        </FormItem>
        <FormItem label="启停状态" prop="enabledState">
          <Select v-model="params.enabledState" clearable placeholder="启停状态" style="width: 120px">
            <Option v-for="(item,index) in baseData['设备其他状态']" :value="item.value" :key="index">{{ item.name }}</Option>
          </Select>
        </FormItem>
        <FormItem label="设备使用状态" prop="useState" show>
          <Select v-model="params.useState" clearable placeholder="设备使用状态" style="width: 120px">
            <Option v-for="(item,index) in baseData['设备使用状态']" :value="item.value" :key="index">{{ item.name }}</Option>
          </Select>
        </FormItem>
        <FormItem label="名称" prop="name" show>
          <Input placeholder="名称" clearable style="width: 150px;" v-model="params.name"></Input>
        </FormItem>
      </template>
      <template slot="btn">
        <ButtonGroup>
          <Button type="primary" @click="add">新增</Button>
        </ButtonGroup>
      </template>
      <template slot="detail">
        <Detail :mid="detailID" :seqNo="tableSelectItem.length === 1 ? tableSelectItem[0].seqNo : null"></Detail>
      </template>
    </ys-page-list>
    <ys-modal v-model="modShow" :width="800" title="新增/修改">
      <Edit :mid="detailID" :seqNo="tableSelectItem.length === 1 ? tableSelectItem[0].seqNo : null" @on-ok="getList"></Edit>
    </ys-modal>
  </div>
</template>

<script>
import pageList from '@/mixins/pageList.js'
import Edit from "@/views/base/smartDevice/edit.vue";
import Detail from "@/views/base/smartDevice/detail.vue";
import {setEnabledStateBySeqNo} from "@/api/base";

export default {
  name: 'smartDevice',
  mixins: [pageList],
  components: {
    Edit,
    Detail
  },
  data() {
    return {
      params: {
        type: '',
        state: -1,
        enabledState: -1,
        useState: -1,
        name: '',
      },
      modShow: false,
      headerColumns: [
        {type: 'selection', title: '选择框', width: 60, align: 'center'},
        {type: 'index', title: '序号', width: 50, align: 'center'},
        {width: 150, title: '名称', key: 'name', align: 'center', renderConfig: {type: 'gotoDetail'}},
        {width: 100, title: '设备类型', key: 'type', align: 'center', renderConfig: {type: 'baseData', parentName: '设备类型'}},
        {width: 100, title: '供应商', key: 'operator', align: 'center'},
        {width: 150, title: '设备序列号', key: 'seqNo', align: 'left',},
        // {width: 100, title: '设备参数', key: '', align: 'center'},
        {width: 150, title: '所属机构', key: 'orgName', align: 'left',},
        {width: 120, title: '设备状态', key: 'state', align: 'center', renderConfig: {type: 'baseData', parentName: '设备状态'}},
        {width: 120, title: '启停状态', key: 'enabledState', align: 'center', renderConfig: {type: 'baseData', parentName: '设备其他状态'}},
        {width: 120, title: '设备使用状态', key: 'useState', align: 'center', renderConfig: {type: 'baseData', parentName: '设备使用状态'}},
        {minWidth: 200, title: '备注', key: 'des', align: 'left',},
        {width: 170, title: '创建时间', key: 'createdAt', align: 'center', },
      ]
    }
  },
  computed: {
    multiBtn() {
      return [
        {
          children: [
            {
              type: 'detail',
              title: '查看详情',
              icon: 'md-eye',
              disabled: this.tableSelectItem.length !== 1
            },
            {
              click: () => this.modShow = true,
              title: '编辑',
              icon: 'md-create',
              disabled: this.tableSelectItem.length !== 1
            },
            // {
            //   click: () => this.delItem('', {id: this.tableSelectItem[0].id}),
            //   iconColor: '#ed4014',
            //   title: '删除',
            //   icon: 'md-trash',
            //   disabled: this.tableSelectItem.length !== 1 || this.tableSelectItem[0].status === 1
            // },
          ]
        },
        {
          hiddenBtnIcon: true,
          children: [
            {
              click: () => this.setState(),
              title: '启用/暂停',
              icon: 'md-checkmark-circle',
              disabled: this.tableSelectItem.length !== 1 || this.tableSelectItem[0].status === 1
            },
          ]
        }
      ]
    },
  },
  mounted() {
    this.getList();
  },
  methods: {
    setState() {
      this.$Modal.confirm({
        title: '注意',
        content: '是否要启用/停用这个智能设备么？',
        okText: '确定',
        cancelText: '取消',
        onOk: () => {
          const {seqNo} = this.tableSelectItem[0]
          // 0：停用；1：启用；
          const enabledState = this.tableSelectItem[0].enabledState === 1 ? 0 : 1
          setEnabledStateBySeqNo({seqNo, enabledState}).then(res => {
            if (res.code === 200) {
              this.$Notice.success({
                title: '提示',
                desc: '操作成功！'
              });
              this.getList();
            }
          })
        }
      })
    }
  }
}
</script>

<style>

</style>