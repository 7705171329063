<template>
  <div>
    <Form ref="form" class="editForm twoPartsEditForm" :model="form" :rules="ruleValidate" :label-width="100" inline>
      <FormItem label="名称" prop="name">
        <Input v-model="form.name" placeholder="名称"></Input>
      </FormItem>
      <FormItem label="设备序列号" prop="seqNo">
        <Input v-model="form.seqNo" :disabled="!!form.id" placeholder="设备序列号"></Input>
      </FormItem>
      <!-- <FormItem label="设备序列号" prop="seqNo">
        <Input v-model="form.seqNo" placeholder="设备序列号" @on-blur="getConfigBySeqNo" style="width: 60%;"></Input>
        <Button type="primary" :disabled="!form.seqNo" style="width: 40%;" @click="getConfigBySeqNo">搜索设备参数</Button>
      </FormItem> -->
      <FormItem label="设备类型" prop="type">
        <Select v-model="form.type" clearable :disabled="isdisabled" transfer placeholder="设备类型">
          <Option v-for="item in baseData['设备类型']" :value="item.value" :key="item.value">{{ item.name }}</Option>
        </Select>
      </FormItem>
      <FormItem label="供应商" prop="operator">
        <Input v-model="form.operator" placeholder="供应商"></Input>
      </FormItem>
      <FormItem label="所属机构" prop="orgName">
        <Input v-model="form.orgName" disabled @on-clear="clearParent" clearable placeholder="所属机构" style="width: 80%;"></Input>
        <Button type="primary" style="width: 20%;" @click="orgFlag=true">选择</Button>
      </FormItem>
      <FormItem label="启停状态" prop="enabledState">
        <Select v-model="form.enabledState" clearable :disabled="isdisabled" transfer placeholder="启停状态">
          <Option v-for="item in baseData['设备其他状态']" :value="item.value" :key="item.value">{{ item.name }}</Option>
        </Select>
      </FormItem>
      <FormItem label="备注" prop="des" style="width: 100%">
        <Input v-model="form.des" :disabled="isdisabled" type="textarea" maxlength="128" show-word-limit style="width: 100%" :autosize="{minRows: 3,maxRows: 10}" placeholder="备注"></Input>
      </FormItem>
      <!-- <h1 class="h1Title">设备参数</h1>
      <Alert type="warning">请先填写序列号后方可搜索设备参数</Alert>
      后端返回的多的参数
      <FormItem :label="item.title" :prop="'manualDescList.' + index + '.value'" :rules="{required: true, message: (isDataPicker(item) ? '请选择' : '请填写') + item.title, trigger: 'blur'}" v-for="(item, index) in form.manualDescList" :key="index">
        时间选择模式
        <ys-date-picker type="datetime" style="width: 100%;" :placeholder="item.title" v-model="item.value" @on-change="smsManualChange($event, item)" :disabled="item.disabled" v-if="isDataPicker(item)"/>
        输入框模式
        <Input v-model="item.value" @on-change="smsManualChange($event, item)" :disabled="item.disabled" v-else></Input>
      </FormItem> -->

    </Form>
    <div class="modalEditBtnArea">
      <Button type="primary" @click="save">确定并保存</Button>
      <Button @click="close">取消</Button>
    </div>
    <ys-modal v-model="orgFlag" :width="1200" title="选择机构">
      <org-component :limit="1" :oType="1" :oTypeListValue="[1]" @on-ok="getData"></org-component>
    </ys-modal>
  </div>
</template>
<script>
import edit from '@/mixins/edit'
import {hardHatGetBySeqNo, hardHatGetConfigBySeqNo} from "@/api/base";
import OrgComponent from "_c/org";

export default {
  name: 'configureEdit',
  components: {
    OrgComponent
  },
  mixins: [edit],
  props: {
    seqNo: {
      type: [String, Number],
      default: ''
    }
  },
  data() {
    return {
      ruleValidate: {
        name: [
          {type: 'string', required: true, message: '请填写名称', trigger: 'blur'}
        ],
        seqNo: [
          {type: 'string', required: true, message: '请填写设备序列号', trigger: 'blur'}
        ],
        type: [
          {type: 'number', required: true, message: '请选择设备类型', trigger: 'blur'}
        ],
        operator: [
          {type: 'string', required: true, message: '请填写设备供应商', trigger: 'blur'}
        ],
        orgName: [
          {type: 'string', required: true, message: '请选择设备所属机构', trigger: 'blur'}
        ],
        enabledState: [
          {type: 'number', required: true, message: '请选择设备状态', trigger: 'blur'}
        ],
      },
      form: {
        id: '',
        name: '',
        seqNo: '',
        type: 1,
        operator: '',
        orgId: '',
        orgName: '',
        enabledState: 1,
        des: '',

        // manualDescList: [], // 设备参数（现阶段暂时不做，后端默认已配置好了）
      },
      isdisabled: false,
      orgFlag: false,
    }
  },
  computed: {
    isDataPicker() {
      // 通过判断value（即键值名）中是否含有关键词段
      const List = ['时间', '日期']
      return (item) => {
        return (isNaN(item.value) && !isNaN(Date.parse(item.value))) || List.some(ite => item.title.indexOf(ite) !== -1)
      }
    }
  },
  mounted() {
    if (this.mid) {
      hardHatGetBySeqNo({seqNo: this.seqNo}).then(res => {
        Object.keys(this.form).forEach((item) => {
          this.form[item] = res.data[item]
        })
      })
    }
    // this.getConfigBySeqNo()
  },
  methods: {
    // 获取设备参数（需先拿到设备序列号。现阶段暂时不做，后端默认已经做好配置了）
    getConfigBySeqNo() {
      if(!this.form.seqNo) return
      hardHatGetConfigBySeqNo({seqNo: this.form.seqNo}).then(res => {
        console.log('设备参数', res);
      })
    },
    getData(data) {
      this.form.orgName = data[0].iname
      this.form.orgId = data[0].id
    },
    clearParent() {},
    save() {
      this.ok(this.form.id ? '/hardHat/UpdateBySeqNo' : '/hardHat/Create');
    }
  }
}
</script>

<style lang="less" scoped>

</style>


